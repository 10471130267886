<template lang="pug">
.flex.flex-col.w-full(style='height: 75vh')
  p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ notification.type.value }}
  .flex.flex-col.mt-3.relative
    p.text-annotationColor.text-base.font-SourceSansPro.font-semibold {{ $t("notifications.notificationName") }}
    el-input.mt-3(
      v-model='notification.name',
      class='w-1/2',
      :disabled='isAllowedCreate',
      :class='{ "input-error": isNameMissing }'
    )
    transition(name='el-zoom-in-top')
      .text-notify.text-xs.top-full.left-0.absolute(v-if='isNameMissing') {{ $t("notifications.enterName") }}
  .mt-5.flex.flex-col
    p.font-bold.text-darkblue.text-xl.font-SourceSansPro {{ $t("notifications.view") }}
    el-select.mt-3(
      class='w-1/2',
      :disabled='isAllowedCreate',
      v-model='notification.type',
      @change='$emit("extraChange", notification.type)'
    )
      el-option.text-ellipsis(
        style='max-width: 209px',
        v-for='item in notificationTypes',
        :key='item.key',
        :label='item.value',
        :value='item'
      )
  .flex.flex-col.mt-4.relative(
    v-if='notification.type.key === "geozone_notification" || notification.type.key === "sensor_notification" || notification.type.key === "velocity_notification" || notification.type.key === "alarm_notification" || notification.type.key === "filling_notification"'
  )
    p.text-annotationColor.text-base.font-SourceSansPro.font-semibold {{ $t("notifications.responsetime") }}
    el-input.mt-2(
      v-model='notification.min_trigger_duration_value',
      type='number',
      class='w-1/2',
      :disabled='isAllowedCreate'
    )
  .flex.flex-col.mt-4.relative(
    v-if='notification.type.key === "geozone_notification" || notification.type.key === "sensor_notification" || notification.type.key === "velocity_notification" || notification.type.key === "alarm_notification" || notification.type.key === "filling_notification"'
  )
    p.text-annotationColor.text-base.font-SourceSansPro.font-semibold {{ $t('notifications.period') }}
    el-select.mt-3(class='w-1/2', v-model='notification.trigger_duration_type')
      el-option(
        v-for='item in duration_type',
        :key='item.key',
        :label='item.value',
        :value='item'
      )
  .flex.flex-col.mt-4.relative.border-b.border-dividerColor.pb-8(
    v-if='notification.type.key !== "lostsignal_notification" '
  )
    p.text-annotationColor.text-base.font-SourceSansPro.font-semibold {{ $t('notifications.periodcontrol') }}
    el-select.mt-3(class='w-1/2', v-model='notification.time_control_type')
      el-option(
        v-for='item in notificationTimeControl',
        :key='item.key',
        :label='item.value',
        :value='item'
      )
  span.font-bold.pl-1.text-darkblue.text-xl.font-SourceSansPro.mt-4 {{ $t("notifications.objects") }}
  .w-full.flex.items-center.justify-between.bg-white
    skif-search.bg-white.my-2(
      v-model='filterString'
      :placeholder='$t("search")'
      style='width: 210px'
      @searching="searching"
    )
    .flex.cursor-pointer.fixed.ml-2(v-if='isOnlyUnits', style='left: 240px')
      svg.ml-4(
        @click.stop='isShowAllUnits = !isShowAllUnits',
        width='20',
        height='20',
        viewBox='0 0 18 18',
        fill='none',
        xmlns='http://www.w3.org/2000/svg'
      )
        path(
          fill-rule='evenodd',
          clip-rule='evenodd',
          d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z',
          :fill='!showAllUnits ? "#F56C6C" : "#5477A9"'
        )
        path(
          fill-rule='evenodd',
          clip-rule='evenodd',
          d='M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z',
          :fill='!showAllUnits ? "#F56C6C" : "#5477A9"'
        )
      transition(name='slide-fade', mode='out-in')
        .absolute.bg-white.px-2.py-4.pl-3(
          v-if='isShowAllUnits',
          style='left: 20px; border: 1px solid #cfdbeb; bottom: 25px; width: 320px; border-radius: 5px'
        )
          .text-darkblue.font-SourceSansPro.text-sm.font-semibold
            .flex.mb-2
              svg.mt-2.mr-4(
                width='20',
                height='20',
                viewBox='0 0 18 18',
                fill='none',
                xmlns='http://www.w3.org/2000/svg'
              )
                path(
                  fill-rule='evenodd',
                  clip-rule='evenodd',
                  d='M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z',
                  fill='#5477A9'
                )
                path(
                  fill-rule='evenodd',
                  clip-rule='evenodd',
                  d='M13.3446 7.18139C13.3196 7.18139 13.2957 7.17718 13.2715 7.17467L4.64671 7.18287V7.18036C4.28874 7.17592 4 6.91396 4 6.59065C4 6.26437 4.29341 6 4.65558 6C4.66227 6 4.66846 6.00148 4.67516 6.00182H13.3248C13.3315 6.00171 13.3377 6 13.3444 6C13.7066 6 14 6.26435 14 6.59065C14.0001 6.91692 13.7067 7.18139 13.3446 7.18139ZM6.17718 8.74201C6.18387 8.74201 6.19007 8.74349 6.19676 8.74383H11.8031C11.8098 8.74349 11.816 8.74201 11.8227 8.74201C12.1848 8.74201 12.4783 9.00636 12.4783 9.33265C12.4783 9.65893 12.1848 9.9233 11.8227 9.9233C11.7977 9.9233 11.7738 9.9192 11.7495 9.91659L6.16809 9.9249V9.92239C5.81013 9.91795 5.52138 9.65599 5.52138 9.33256C5.52138 9.00649 5.81526 8.74201 6.17718 8.74201ZM7.9163 11.4839C7.923 11.4839 7.92919 11.4855 7.93589 11.4857H10.0642C10.0709 11.4856 10.0771 11.4839 10.0838 11.4839C10.4459 11.4839 10.7394 11.7485 10.7394 12.0745C10.7394 12.4008 10.4459 12.6652 10.0838 12.6652C10.0588 12.6652 10.0349 12.661 10.0106 12.6585L7.90744 12.6667V12.6642C7.54947 12.6597 7.26073 12.3978 7.26073 12.0743C7.26073 11.7485 7.55426 11.4839 7.9163 11.4839Z',
                  fill='#5477A9'
                )
              .flex.flex-col
                span {{ $t('reports.applied_filter') }}
                span {{ $t('unitsVisiblityTranslater.notShowAllObject') }}
            .flex.items-center
              skif-checkbox.mr-4.ml-1(
                v-model='showAllUnits',
                @change='toggleAllUnits'
              )
              span.text-darkblue.font-SourceSansPro.text-sm.font-semibold {{ $t('reports.show_all_objects') }}

  .flex.w-full.items-center.justify-end.mb-2.pr-1
    span.text-darkblue.font-SourceSansPro.text-sm.font-semibold.mr-4 {{ $t('select_all') }}
    skif-checkbox(
      v-model='isAllChecked',
      :indeterminate='isIndeterminate',
      :disabled='!filterData.length',
      :error='isError'
    )
  .overflow-y-auto.mt-2(ref='scroll-table', style='min-height: 380px')
    .table.w-full
      .table-header-group.text-left.border-b.border-reallylightblue.bg-white
        .table-cell.bg-white.top-0.sticky.w-full.text-right

      .table-row-group
        .table-row.border-b.border-reallylightblue(
          v-for='unit in currentUnits',
          :key='unit.id',
          style='height: 43px'
        )
          .table-cell.text-ellipsis(style='max-width: 362px')
            span.truncate.leading-7.font-bold.text-sm.transition-colors.duration-300(
              :class='checkedUnits.indexOf(unit.id) !== -1 ? "text-darkblue" : "text-annotationColor"'
            ) {{ unit.name }}

          .table-cell.w-10.pl-1
            skif-checkbox(
              v-model='checkedUnits',
              :label='unit.id',
              :showLabel='false'
            )
        .table-row(:style='{ height: this.bottomPaddingHeight + "px" }')
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: ['notification', 'admin'],
  data() {
    return {
      showAllUnits: false,
      isOnlyUnits: false,
      isShowAllUnits: false,
      checkedUnits: [],
      filterString: '',
      isNameMissing: false,
      isError: false,
      totalHeight: 0,
      cellHeight: 43,
      viewportHeight: 0,
      bufferHeight: 0,
      toleranceHeight: 43 * 5,
      tolerance: 5,
      bottomPaddingHeight: 0,
      topPaddingHeight: 0,
      bufferedItems: 0,
      startIndex: 0,
      currentUnits: [],
      customUnits: []
    }
  },
  computed: {
    visibleItems: {
      get() {
        return this.filterData.slice(
          this.startIndex,
          this.startIndex + this.bufferedItems
        )
      },
      set(val) {
        this.customUnits = val
        return val
      }
    },
    ...mapState('dictionary', {
      notificationTypes: state => state.notification_type,
      notificationTimeControl: state => state.notification_time_control,
      duration_type: state => state.trigger_duration_type
    }),
    unitIds() {
      return this.filterData.map(k => k.id)
    },
    ...mapGetters('login', ['role', 'disabledRole', 'notRole', 'me']),
    ...mapState('units', {
      units: state => state.defaultUnits
    }),
    isAllowedCreate() {
      return this.disabledRole && this.role !== 'OPERATOR'
    },
    isIndeterminate() {
      return (
        this.checkedUnits.length > 0 && this.checkedUnits.length < this.filterData.length
      )
    },
    isAllChecked: {
      get() {
        return (
          this.checkedUnits.length >= this.filterData.length && this.filterData.length
        )
      },
      set(value) {
        this.checkedUnits = value ? this.unitIds : []
      }
    },

    filterData() {
      if (this.admin) {
        return this.notification.units.filter(
          unit =>
            JSON.stringify(unit)
              .toLowerCase()
              .indexOf(this.filterString.toLowerCase()) !== -1
        )
      }
      const act = this.showAllUnits
        ? this.units.filter(
            unit =>
              JSON.stringify(unit)
                .toLowerCase()
                .indexOf(this.filterString.toLowerCase()) !== -1
          )
        : this.customUnits.filter(
            unit =>
              JSON.stringify(unit)
                .toLowerCase()
                .indexOf(this.filterString.toLowerCase()) !== -1
          )
      this.currentUnits = act

      return act
    }
  },
  watch: {
    'notification.name': function (val) {
      this.isNameMissing = val === ''
    },
    checkedUnits(val) {
      this.$store.dispatch('notifications/setCheckedUnits', val)
      this.isError = false
    }

    // filterData() {
    //   const valIds = this.notification.units.map(k => k.id)
    //   this.isAllChecked =
    //     !!this.unitIds.length &&
    //     this.unitIds.every(unitId => valIds.includes(unitId))
    //   this.isIndeterminate =
    //     !this.isAllChecked &&
    //     this.unitIds.some(unitId => valIds.includes(unitId))
    // }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    toggleAllUnits() {
      if (this.showAllUnits) {
        this.currentUnits = this.units
      } else if (this.customUnits.length) {
        this.currentUnits = this.customUnits
      } else {
        this.currentUnits = this.units
      }
      // this.showAllUnits
      //   ? (this.currentUnits = this.units)
      //   : this.customUnits.length
      //   ? (this.currentUnits = this.customUnits)
      //   : (this.currentUnits = this.units)
    },
    handleScroll(event) {
      this.startIndex = Math.round(event.target.scrollTop / this.cellHeight)
      this.topPaddingHeight = Math.max(this.startIndex * this.cellHeight, 0)
      this.bottomPaddingHeight = Math.max(
        this.totalHeight - this.topPaddingHeight - this.bufferHeight,
        0
      )
    },
    setChosenUnitsError() {
      this.isError = true
    }
    // checkAllUnits($event) {
    //   if ($event.target.checked) {
    //     this.notification.units = this.filterData
    //     this.isError = false
    //     this.isAllChecked = true
    //     this.isIndeterminate = false
    //   } else {
    //     this.notification.units = []
    //     this.isError = true
    //     this.isAllChecked = false
    //     this.isIndeterminate = false
    //   }
    // },
    // changeChecked(unit, $event) {
    //   this.notification.units = $event.target.checked
    //     ? [...this.notification.units, unit]
    //     : this.notification.units.filter(
    //       notifyUnit => notifyUnit.id !== unit.id
    //     )
    //   const selectedLength = this.notification.units.length
    //   if (selectedLength === this.filterData.length) {
    //     this.isError = false
    //     this.isAllChecked = true
    //     this.isIndeterminate = false
    //   } else if (!selectedLength) {
    //     this.isError = true
    //     this.isAllChecked = false
    //     this.isIndeterminate = false
    //   } else {
    //     this.isError = false
    //     this.isAllChecked = false
    //     this.isIndeterminate = true
    //   }
    // }
  },

  created() {
    const valIds = this.notification.units.map(k => k.id)
    this.units.sort((a, b) => (valIds.includes(b.id) ? 1 : -1))
    this.checkedUnits = valIds
    const filters = JSON.parse(
      localStorage.getItem(`filtredUnits_${this.me.active_company.id}`)
    )

    if (filters && filters.units && filters.units.length) {
      const set = new Set()
      for (const id of filters.units) {
        set.add(id)
      }
      const filtredMonitoringUnits = this.units.filter(item => set.has(item.id))
      this.customUnits = filtredMonitoringUnits
      if (filters.units.length === this.units.length) {
        this.currentUnits = this.units
        this.isOnlyUnits = false
      } else {
        this.isOnlyUnits = true
        this.currentUnits = filters.units
      }
    } else {
      this.customUnits = this.units
      this.isOnlyUnits = false
    }
  }
}
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.4s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
</style>
